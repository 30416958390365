import styled from 'styled-components';

const Button = styled.button`
  display: block;
  border: 0;
  padding: 0;
  font-family: open-sans, sans-serif;
  font-size: 18px;
  min-width: 320px;
  height: 50px;
  padding: 0 30px;
  line-height: 55px;
  background-color: #6200ff;
  color: #ffffff;
  border-radius: 6px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  margin-left: ${({ center }) => (center ? '50%' : '0')};
  transform: ${({ center }) => (center ? 'translateX(-50%)' : 'translateX(0)')};
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  text-align: center;
  text-decoration: none;

  @media only screen and (max-width: 620px) {
    width: 100%;
    min-width: auto;
  }
`;

export default Button;
