import styled from 'styled-components';

const InputLabel = styled.label`
  font-family: open-sans, sans-serif;
  font-size: 11px;
  line-height: 16px;
  text-transform: uppercase;
  color: #cdc8d5;
  cursor: pointer;
`;

export default InputLabel;