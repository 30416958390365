import styled from 'styled-components';

function getWidth(props) {
  const propKeys = Object.keys(props);

  if (propKeys.includes('medium')) {
    return '580px';
  }

  if (propKeys.includes('full')) {
    return '100vw';
  }

  return 'auto';
}

const Panel = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #eae6f0;
  padding: 60px 30px;
  width: ${(props) => getWidth(props)};
  margin: 40px;
  overflow: hidden;
  z-index: 10;
  box-sizing: border-box;


  @media only screen and (max-width: 620px) {
    width: 100vw;    
    border-radius: 0;
    border: none;
    margin-top: 0;
    margin-bottom: 20px;;
  }
`;

export default Panel;
