import React from 'react';
import PropTypes from 'prop-types';
import hiretimeLogo from '../assets/images/hiretime-logo.png';
import styled from 'styled-components';

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 30px;
  height: 30px;
  @media only screen and (max-width: 620px) {
    position: relative;
    top: initial;
    height: 20px;
    padding: 20px;
  }
`;

const HiretimeLogoImage = styled.img`
  width: 161px;

  @media only screen and (max-width: 620px) {
    width: auto;
    height: inherit;
  }
`;

const Divider = styled.div`
  height: 24px;
  width: 1px;
  background-color: #b5b5b5;
  margin: 0 30px;

  @media only screen and (max-width: 620px) {
    margin: 0 10px;
  }
`;

const CustomerLogoImage = styled.img`
  height: inherit;
`;

function Header({ name, logoImage }) {
  return (
    <HeaderContainer>
      <HiretimeLogoImage src={hiretimeLogo} alt="hiretime-logo" />
      <Divider />
      <CustomerLogoImage src={logoImage} alt={`${name}-logo`} />
    </HeaderContainer>
  );
}

Header.propTypes = {
  name: PropTypes.string.isRequired,
  logoImage: PropTypes.string.isRequired,
};

export default Header;
