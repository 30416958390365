import styled from 'styled-components';

const H1 = styled.h1`
  font-size: 36px;
  line-height: 36px;
  font-family: open-sans, sans-serif;
  color: #33384e;
  margin: 0;
  padding: 0;
`;

export default H1;
