import styled from 'styled-components';

const InputError = styled.p`
  font-family: open-sans, sans-serif;
  font-size: 14px;
  line-height: 0;
  color: red;
  height: 30px;
  display: block;
  margin: 0;
  text-align: right;
  line-height: 30px;
  padding: 0 10px;
`;

export default InputError;