import styled from 'styled-components';

function generateTextColor({ red, bold }) {
  if (red) {
    return '#EA1010';
  }

  if (bold) {
    return '#000';
  }

  return '#6a6671';
}

const Text = styled.p`
  margin: 0;
  padding: 0;
  font-family: open-sans, sans-serif;
  color: ${(props) => generateTextColor(props)};
  max-width: ${({ medium }) => (medium ? '580px' : 'auto')};
  line-height: 25px;
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
`;

export default Text;
