import React from 'react';

import { CompanyHeaderLayout } from '../components/layouts';
import { useFirestoreDocData, useFirestore, SuspenseWithPerf } from 'reactfire';
import { Redirect } from 'react-router-dom';

function SuccessState({ companyId, payingUserId }) {
  const companiesRef = useFirestore()
    .collection('payingUsers')
    .doc(payingUserId);

  const data = useFirestoreDocData(companiesRef);

  const { paymentState, code } = data;

  if (!paymentState) {
    return <Redirect to={{ pathname: `/${companyId}/failure/` }} />;
  }

  if (paymentState === 'open') {
    return 'We verwerken je betaling...';
  }

  if (paymentState === 'paid') {
    return <Redirect to={{ pathname: `/${companyId}/success/${code}` }} />;
  }

  return '...';
}

function SuccessView({ match }) {
  const { companyId, payingUserId } = match.params;

  return (
    <CompanyHeaderLayout companyId={companyId}>
      <SuspenseWithPerf>
        <SuccessState payingUserId={payingUserId} companyId={companyId} />
      </SuspenseWithPerf>
    </CompanyHeaderLayout>
  );
}

export default SuccessView;
