import React, { useState } from 'react';
import styled from 'styled-components';

import { useForm } from 'react-hook-form';
import { CompanyHeaderLayout } from '../components/layouts';
import { SuspenseWithPerf, useFunctions } from 'reactfire';

import {
  Panel,
  H1,
  Input,
  Button,
  InputLabel,
  InputError,
  Text,
  Link,
  LayoutGroup,
} from '../components/global/';

const BottomText = styled(Text)`
  @media only screen and (max-width: 620px) {
    padding: 0 30px;
    font-size: 14px;
    line-height: 18px;
  }
`;

function PaymentFailed({ show }) {
  if (!show) {
    return null;
  }

  return (
    <LayoutGroup margin-bottom-medium>
      <Text red bold>
        De betaling is helaas niet gelukt
      </Text>
      <Text>Laten we het opnieuw proberen</Text>
    </LayoutGroup>
  );
}

function MailForm({ companyId }) {
  const [email, setEmail] = useState('');
  const [mollieLink, setMollieLink] = useState(null);
  const [allowedToClickPayButton, setAllowedToClickPayButton] = useState(true);
  const { register, handleSubmit, errors } = useForm();

  const functions = useFunctions();
  functions.region_ = 'europe-west1';

  const onSubmit = async (data) => {
    setAllowedToClickPayButton(false);

    try {
      const { _links } = (
        await functions.httpsCallable('doPaymentRequest')({
          email,
          companyId,
        })
      ).data;

      setMollieLink(_links.checkout.href);
    } catch (err) {
      console.log(err);
    } finally {
      setAllowedToClickPayButton(true);
    }
  };

  if (mollieLink) {
    return (
      <>
        <LayoutGroup margin-bottom-medium>
          <H1>Betalen</H1>
        </LayoutGroup>
        <LayoutGroup margin-bottom-medium>
          <Text>
            Je gaat nu een betaling doen van <b>€12,95</b> met email adres:{' '}
            <b>{email}</b> voor Hiretime.
          </Text>
        </LayoutGroup>
        <LayoutGroup margin-top-medium>
          <Button as="a" href={mollieLink} center>
            Nu betalen
          </Button>
        </LayoutGroup>
      </>
    );
  }

  return (
    <SuspenseWithPerf
      fallback={'Opening payment flow'}
      traceId={'adding payment'}
    >
      <LayoutGroup margin-bottom-medium>
        <H1>Wat is je mailadres?</H1>
      </LayoutGroup>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputLabel htmlFor="test">Emailadres</InputLabel>
        <Input
          name="email"
          id="test"
          ref={register({
            pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
          })}
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <InputError>
          {errors.email && 'Dit is geen geldig e-mail adres'}
        </InputError>
        <LayoutGroup>
          <Button
            marginTop
            center
            disabled={!allowedToClickPayButton || !email || email.length === 0}
          >
            Betaling aanvragen
          </Button>
        </LayoutGroup>
      </form>
    </SuspenseWithPerf>
  );
}

function AskMailView({ match }) {
  const failure = match.path.includes('failure');
  const { companyId } = match.params;

  return (
    <CompanyHeaderLayout companyId={companyId}>
      <Panel medium>
        <PaymentFailed show={failure} />

        <MailForm companyId={companyId}></MailForm>
      </Panel>
      <BottomText medium>
        Om Hiretime te gebruiken vragen we je eenmalig om een betaling van
        €12,95 waarmee je een persoonlijke code ontvangt en je in de app kunt
        inloggen.
        <br />
        <Link>Meer weten?</Link>
      </BottomText>
    </CompanyHeaderLayout>
  );
}

AskMailView.propTypes = {};

export default AskMailView;
