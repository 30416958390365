import React from 'react';
import styled from 'styled-components';

import { CompanyHeaderLayout } from '../components/layouts';
import { Panel, H1, Text, LayoutGroup } from '../components/global/';

import appStoreImageUrl from '../assets/images/app-store.png';

const PersonalCodeStyled = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 620px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Code = styled.div`
  height: 54px;
  font-size: 24px;
  line-height: 54px;
  font-weight: 800;
  color: #000;
  background-color: #eeecf2;
  border-radius: 8px;
  padding: 0 30px;
  margin-left: 20px;

  @media only screen and (max-width: 620px) {
    margin-left: 0;
    margin-top: 10px;
  }
`;

const PanelBottom = styled.div`
  height: 90px;
  background-color: #f4f2e9;
  border-top: 1px solid #eae6f0;
  margin-bottom: -60px;
  margin-left: -30px;
  margin-right: -30px;
  margin-top: 40px;
  padding: 0 30px;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 620px) {
    justify-content: center;
    ${Text} {
      display: none;
    }
  
  }
`;

const AppStoreLink = styled.a`
  height: 50px;
  display: block;
  margin-left: 20px;
  @media only screen and (max-width: 620px) {
    margin: 0 10px;
  }
`;

const AppStoreImage = styled.img`
  height: ${({ small }) => (small ? '34px' : 'inherit')};
  padding-top: ${({ small }) => (small ? '8px' : 0)};
`;

function PersonalCode({code}) {
  return (
    <PersonalCodeStyled>
      <Text bold> Jouw persoonlijke code:</Text>
      <Code>{code}</Code>
    </PersonalCodeStyled>
  );
}

function SuccessView({ match }) {
  const { companyId, code } = match.params;

  return (
    <CompanyHeaderLayout companyId={companyId} showConfetti>
      <Panel medium>
        <LayoutGroup margin-bottom-medium>
          <H1>Gelukt!</H1>
        </LayoutGroup>
        <LayoutGroup margin-bottom-big>
          <PersonalCode code={code}></PersonalCode>
        </LayoutGroup>
        <LayoutGroup>
          <Text>
            We versturen deze ook naar het door jou opgegeven e-mailadres. Je
            kunt met deze persoonlijke code in de app inloggen.
          </Text>
        </LayoutGroup>
        <PanelBottom>
          <Text bold>Heb je de app nog niet?</Text>
          <AppStoreLink
            target="_blank"
            href="https://play.google.com/store/apps/details?id=df.hiretime&hl=nl&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          >
            <AppStoreImage
              alt="Ontdek het op Google Play"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/nl_badge_web_generic.png"
            />
          </AppStoreLink>
          <AppStoreLink
            target="_blank"
            href="https://apps.apple.com/nl/app/hiretime/id1471773611"
          >
            <AppStoreImage
              small
              alt="Ontdek het op Apple Store"
              src={appStoreImageUrl}
            />
          </AppStoreLink>
        </PanelBottom>
      </Panel>
    </CompanyHeaderLayout>
  );
}

export default SuccessView;
