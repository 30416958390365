import styled from 'styled-components';

function marginCalculator(props) {
  const sizePossibilities = { small: 20, medium: 30, big: 40 };
  let margins = { left: 0, top: 0, bottom: 0, right: 0 };

  Object.keys(props).forEach((prop) => {
    const [keyword, direction, size] = prop.split('-');

    if (keyword !== 'margin' && keyword !== 'mg') {
      return;
    }

    margins[direction] = sizePossibilities[size];
  });

  return Object.values(margins)
    .map((value) => (value === 0 ? value : `${value}px`))
    .join(' ');
}

const LayoutGroup = styled.div`
  margin: ${(props) => marginCalculator(props)};
`;

export default LayoutGroup;
