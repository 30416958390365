import styled from 'styled-components';

const Input = styled.input`
  height: 36px;
  line-height: 36px;
  font-family: open-sans, sans-serif;
  border: 1px solid #eae6f0;
  font-size: 18px;
  display: block;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  border-radius: 6px;
  width: 100%;
  padding: 0 9px;
`;

export default Input;