import React from 'react';
import { useFirestoreDocData, useFirestore, SuspenseWithPerf } from 'reactfire';
import Header from '../Header.jsx';
import styled from 'styled-components';
import confettiBgImage from '../../assets/images/confetti.png';

import { Page } from '../global/';

function FirebaseHeader({ companyId }) {
  const companiesRef = useFirestore().collection('companies').doc(companyId);

  const { name, logoImage } = useFirestoreDocData(companiesRef);

  return <Header name={name} logoImage={logoImage} />;
}

const ConfettiHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100vw;
  height: 300px;
  z-index: 0;
  background-image: url(${confettiBgImage});
  background-position: bottom;
  background-size: 75%;
`;

function CompanyHeaderLayout({ companyId, showConfetti, children }) {
  return (
    <SuspenseWithPerf
      fallback={'loading page status...'}
      traceId={'loading header status'}
    >
      <Page>
        {showConfetti ? <ConfettiHeader /> : null}
        <FirebaseHeader companyId={companyId} />
        {children}
      </Page>
    </SuspenseWithPerf>
  );
}

export default CompanyHeaderLayout;
