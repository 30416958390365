import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import AskMailView from './views/AskMailView';
import SuccessView from './views/SuccessView';
import ProcessingView from './views/ProcessingView';

function App() {
  return (
    <Router>
      <Route exact path="/:companyId" component={AskMailView} />
      <Route exact path="/:companyId/failure" component={AskMailView} />
      <Route exact path="/:companyId/success/:code" component={SuccessView} />
      <Route exact path="/:companyId/processing/:payingUserId" component={ProcessingView} />
    </Router>
  );
}

export default App;
